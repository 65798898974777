import { createContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';

const initialState = {
  filters: null,
  categories: null,
  filterInputs: null,
  isInitialised: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'INIT': {
      // Log the 'payload' property of the action
      console.log('payload', action.payload);

      // Extract 'filters' and 'categories' properties from the 'data' property of the action payload
      const { filters, categories } = action.payload.data || {};

      // Check if 'filters' property exists, if not, provide a default value (e.g., empty array)
      const extractedFilters = filters || [];

      // Check if 'categories' property exists, if not, provide a default value (e.g., empty array)
      const extractedCategories = categories || [];

      // Log the extracted 'filters' and 'categories' values
      console.log({ filters: extractedFilters, categories: extractedCategories });

      // Return a new state object by spreading existing state properties
      // Update 'filters' and 'categories' properties with the extracted values
      // Set 'isInitialised' property to true and 'isError' property to false
      return {
        ...state,
        filters: extractedFilters,
        categories: extractedCategories,
        isInitialised: true,
        isError: false
      };
    }

    case 'SUBMIT': {
      const { suggestions } = action.payload;
      console.log({ ...state, suggestions });
      return { ...state, suggestions };
    }

    default:
      return state;
  }
}

const HomeContext = createContext({
  ...initialState,
  LoadAll: () => {},
  submit: () => {}
});

export const HomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const submit = async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/filter_search/submit', {
        data
      });
      console.log(response.data);
      // dispatch({ type: 'SUBMIT',payload:{suggestions} });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_CLIENT_API_URL}/filter_search/GET_ALL`
        );
        dispatch({ type: 'INIT', payload: { data } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  // if (!state.isInitialised) return <MatxLoading />;

  return <HomeContext.Provider value={{ ...state, submit }}>{children}</HomeContext.Provider>;
};

export default HomeContext;
