import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import categoryReducer from 'app/features/real_estates_properties/home/slices/categorySlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['category'], // Add 'category' to whitelist to persist category state
};

const rootReducer = combineReducers({
  category: categoryReducer,
  // Add other reducers here if necessary
});

export { rootPersistConfig, rootReducer };
