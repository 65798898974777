import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Main = Loadable(lazy(() => import('../home/pages/Main')));
const Item = Loadable(lazy(() => import('../home/pages/Item')));
const CustomerPortal = Loadable(lazy(() => import('../home/pages/CustomerPortal')));
const MoreDetails = Loadable(lazy(() => import('../home/components/MapComponent')));

const RealEstateRoutes = [
  { path: '/', element: <Main /> },
  { path: '/landing/item/:id', element: <Item /> },
  { path: 'customerportal', element: <CustomerPortal /> },
  { path: '/moreDetails/:name', element: <MoreDetails /> } // Updated to include dynamic :id parameter
];

export default RealEstateRoutes;
